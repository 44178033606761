Platform.Deferred.execute(setListViewScript);
function LoadFilters() {
    var root = $('body').data('root');
     $('.product-code').matchHeight();
    $('.sub-cat-holder-match-height').matchHeight();
    $('.product-name').matchHeight();
    $('.product-info-match-height').matchHeight();
    $('.product-match-height').matchHeight();
   

    var wrapper = $('#pf-product-list-page');
    var filter = wrapper.data("filter");
    var colourFilter = wrapper.data("filter-colour");
    var brandFilter = wrapper.data("brand-filter");
    var catId = wrapper.data("cat-id");
    if (catId == "undefined") { catId = "" };
    var brandId = wrapper.data("brand-id");
    if (brandId == "undefined") { brandId = "" };
    var searchString = encodeURIComponent(wrapper.data("search-string"));
    var filterEnabled = wrapper.data("filter-enabled");
    var filterDisplay = wrapper.data("filter-on");
    var listType = wrapper.data("list-type");
    var minPriceFilter = wrapper.data("min-price-filter");
    var maxPriceFilter = wrapper.data("max-price-filter");

    if (filterEnabled == 'True' && filterDisplay == 'True') {
        $('#filter').html("<div class='pf-loading-spinner'></div>").load(root + "Widgets/ProductFilterWidget?listType=" + listType + "&catId=" + catId + "&brandId=" + brandId +
            "&searchString=" + searchString + "&filter=" + filter + "&brandFilter=" + brandFilter +
            "&minPriceFilter=" + minPriceFilter + "&maxPriceFilter=" + maxPriceFilter + "&colourFilter=" + colourFilter);

        $('#filtersOnly').load(root + "Widgets/GetSpecFilters?catId=" + catId + "&brandId=" + brandId +
            "&searchString=" + searchString + "&filter=" + filter + "&brandFilter=" + brandFilter + "&colourFilter=" + colourFilter);
        $('#filtersColourOnly').load(root + "Widgets/GetColourFilters?catId=" + catId + "&brandId=" + brandId +
            "&searchString=" + searchString + "&filter=" + filter + "&brandFilter=" + brandFilter + "&colourFilter=" + colourFilter);

    }
}
function setListViewScript(){
    var root = $('body').data('root');
    $('.sub-cat-holder-match-height').matchHeight();
    $('.product-match-height').matchHeight();
    $('.product-info-match-height').matchHeight();

    var wrapper = $('#pf-product-list-page');
    LoadFilters();

    var url = wrapper.data('url');

    // add handler for filter options
    $(document).on('click', '.ecom-filter-val', function () {
        FilterProducts(url);
    });
    $(document).on('click', '.ecom-filter-colour-val', function () {
        FilterProducts(url);
    });

    $(document).on('click', '.pf-paging-btn', function () {
        var pagenum = $(this).data('pagenum');
        FilterProducts(url, pagenum);
    });

    $(document).on('click', '.pf-load-more-btn', function () {
        var limit = $(this).data('limit');
        FilterProducts(url, null, limit);
    });

    $(document).on('click', '.clear-filters-button', function () {
        $(".ecom-filter-val").prop("checked", false);
        $(".ecom-filter-colour-val").prop("checked", false);
        FilterProducts(url);
    });

    $(document).on('click', '.clear-filters-button', function () {
        ClearFilters(url);
    });

    $(document).on('change', '.list-view-select', function () {
        FilterProducts(url);
    });

    $(document).on('change', '.ecom-filter-val-select', function () {
        FilterProducts(url);
    });
    // Mobile filter button
    //$(document).on('click', '.filter-heading.mobile', function ()
    //{                 
    //    if ($('.m-filter-wrapper').hasClass("mobile-filter-visible"))
    //    {
    //        $(".filter-heading-icon").html("+");

    //    }
    //    else {
    //        $(".filter-heading-icon").html("-");
                    
    //    }        
    //    $('.m-filter-wrapper').toggleClass('mobile-filter-visible');  
    //});

    // stock notify handler
    var stockModal = $('[data-remodal-id=stock-notify-modal]').remodal();
    $('.pf-stock-notify-me-btn').click(function () {
        stockNotifyProdId = $(this).data("prod-id");
        stockNotifyIsVariant = $(this).data("is-variant");
        stockNotifyLoggedIn = $(this).data("user-logged-in");
        if (stockNotifyLoggedIn == "False") {
            $('#stock-notify-content').show();
            $('#stock-notify-rsp').hide();
            stockModal.open();
        }
        else {
            RegisterStockNotify();
        }
    });

    $('#register-stock-notify-form').submit(function (e) {
        e.preventDefault();
        stockNotifyEmail = $('#stock-notify-email').val();
        RegisterStockNotify();
    });

    function RegisterStockNotify() {

        $.ajax({
            url: root + "UserAccount/RegisterStockNotify",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                prodId: stockNotifyProdId,
                isVariant: stockNotifyIsVariant,
                email: stockNotifyEmail
            }),
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                if (data.result == "ok") {
                    if (stockNotifyLoggedIn) {
                        $('#stock-notify-content').hide();
                        $('#stock-notify-rsp').show();
                        stockModal.open();
                    }
                    else {
                        $('#stock-notify-content').fadeOut(function () {
                            $('#stock-notify-rsp').fadeIn();
                        });
                    }
                }
                else {
                    alert('Sorry there was a problem processing your request.');
                }
            },
            error: function () {
                alert('Sorry there was a problem processing your request.');
            }
        });
    }

    // adding to cart
    $('.product-list-add-to-cart-btn').click(function () {
        var fbPixelEnabled = $('#product-view-container').data("fb-pixel");
        var cartWarningEnabled = $('#pf-product-list-page').data('cart-warning-enabled') === 'True';
        var btn = $(this);
        var id = btn.data("id");
        var wrapper = btn.closest('.list-view-add-cart-wrapper');
        var qty = wrapper.find('.qty-tbx').val();

        var addToCart = function () {
            $.ajax({
                url: root + "Cart/AddToCart",
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({ productId: id, quantity: qty }),
                contentType: 'application/json; charset=utf-8',
                success: function (data) {
                    //AddedToCart(data);
                    $(document).trigger('itemAdded.product.platform', data);
                    //SetCartQty();
                    if (fbPixelEnabled == "True") {
                        fbq_addToBag();
                    }
                }
            });
        };

        if (cartWarningEnabled) {
            $.ajax({
                url: root + 'Cart/CheckProductExistsInCart',
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({ productId: id }),
                contentType: 'application/json; charset=utf-8',
                success: function (response) {
                    if (response.result === 'ok-not-exists') {
                        addToCart();
                    } else if (response.result === 'ok-exists') {
                        $('#cartWarningModalAddBtn').off('click').click(function () {
                            addToCart();
                        });
                        $('#cartWarningModalQuantity').text(qty);
                        $('[data-remodal-id=cartWarningModal]').remodal().open();
                    }
                }
            });
        } else {
            addToCart();
        }

        return false;
    });

    $('.pf-add-all-to-cart-btn').click(function () {
        var $productsTarget = $($(this).data('products-target'));
        var products = [];
        $productsTarget.find('.pf-product-list-item').each(function () {
            var $this = $(this);
            if ($this.data('has-variants') !== 'True' && $this.data('has-options') !== 'True' && $this.data('has-options-adv') !== 'True') {
                products.push({
                    ProductId: $(this).data('id'),
                    Quantity: $(this).find('.qty-tbx').val()
                });
            }
        });

        $.ajax({
            url: root + 'Cart/AddProductsToCart',
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify(products),
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                $(document).trigger('itemAdded.product.platform', data);
                if (fbPixelEnabled == "True") {
                    fbq_addToBag();
                }
            }
        });

        return false;
    });

    $('.list-view-add-cart-wrapper .pf-qty-btn-minus').click(function () {
        var wrapper = $(this).closest('.qtywrapper');
        var qtyTbx = wrapper.find('.qty-tbx');
        var qty = Number(qtyTbx.val());
        var uom = qtyTbx.data('uom');
        if (qty > uom)
            qty -= uom;
        if (qty < 0)
            qty = 0;
        qtyTbx.val(qty);
    });

    $('.list-view-add-cart-wrapper .pf-qty-btn-plus').click(function () {
        var wrapper = $(this).closest('.qtywrapper');
        var qtyTbx = wrapper.find('.qty-tbx');
        var qty = Number(qtyTbx.val());
        var uom = qtyTbx.data('uom');
        qty += uom;
        qtyTbx.val(qty);
    });

    // group products
    $(".pf-list-view-group-prods-wrapper").on({
        mouseenter: function () {
            var swapIn = $(this).data("image-url");
            var mainImgContainer = $(this).closest('.pf-product-list-item').find('.pf-list-item-img');
            var mainImage = mainImgContainer.prop('src');
            $(this).data("orig-img-url", mainImage);
            mainImgContainer.prop('src', swapIn);
            $(this).addClass('pf-list-group-image-selected');
        },
        mouseleave: function() {
            var swapIn = $(this).data("orig-img-url");
            var mainImgContainer = $(this).closest('.pf-product-list-item').find('.pf-list-item-img');
            mainImgContainer.prop('src', swapIn);
            $(this).removeClass('pf-list-group-image-selected');
        },
        click: function() {
            // Handle click...
        }
    }, ".pf-list-view-group-prod");
}

//function AddedToCart(data) {
//    if (data.result == "ok") {
//        GetAndOpenTopCart();
//        $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
//    }
//    if (data.result == "ok-stock-limited") {
//        alert("Sorry there are only " + data.qty + " of these items available in stock.");
//        GetAndOpenTopCart();
//        $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top
//    }
//    if (data.result == "ok-out-of-stock") {
//        alert("Sorry this item is no longer in stock.");
//        GetTopCart();
//    }
//}

function FilterProducts(url, pagenum, limit) {
    // get the list of selected options and build querystring
    
    
    var attrList = [];
    var colourList=[];
    var s = [];
    var brands = [];
    $('.ecom-filter-group').each(function () {
        var id = $(this).data("filter-attr");
        var filterType = $(this).data("filter-type");
        var found = false;
        var attrItem = [];
        $(this).closest(".pf-filter-dropdown").find(".ecom-filter-val").each(function () {
            if ($(this).is(':checked')) {

                if (filterType == "val") {
                    attrItem.push($(this).data("filter-attr-val"));
                    found = true;
                }
                    //if (filterType == "val") {
                    //    s.push($(this).data("filter-attr-val"));
                    //}
                else if (filterType == "brand") {
                    brands.push($(this).val());
                }
            }
        });
        if (found == true) {
            attrList.push(id + "|" + attrItem.join('-'));
        }
    });
    $('.ecom-colour-group').each(function () {
        var id = $(this).data("filter-attr");
        var filterType = $(this).data("filter-type");
        $(this).closest(".pf-filter-dropdown").find(".ecom-filter-colour-val").each(function () {
            if ($(this).is(':checked')) {

                if (filterType == "col") {
                    colourList.push($(this).data("filter-colour-val"));
                }              
            }
        });
    });
    var wrapper = $('#pf-product-list-page');
    wrapper.data("filter-colour", colourList.join('-'));

    var productFilter = "";
    var cnt = 0;
    for (var i in attrList) {
        productFilter += attrList[i];
        cnt++;
        if (cnt < attrList.length) {
            productFilter += ",";
        }
    }

    //var newUrl = url + "?" + newQuerystring + "#x-product-listing";

    // attributes
    //var qs = s.join('-');
    //// gets new url to call

    var newUrl = UpdateQueryString("pf", productFilter, url);
    wrapper.data('filter', productFilter);

    if (pagenum) {
        newUrl = UpdateQueryString("page", pagenum, newUrl);
    }

    // query limit dropdown
    // if populated update limit value based on parameter.
    if (limit) {
        newUrl = UpdateQueryString("limit", limit, newUrl);
    } else {
        var productLimit = $('#product-limit').val();
        newUrl = UpdateQueryString("limit", productLimit, newUrl);
    }

    //// brands
    qs = brands.join('-');
    newUrl = UpdateQueryString("br", qs, newUrl);
    //Colour
    cs = colourList.join('-');
    newUrl = UpdateQueryString("cf", cs, newUrl);

    // price ranges
    var minPrice = $('#min-price-filter').val();
    if (typeof minPrice != 'undefined' && minPrice != -1) {
        newUrl = UpdateQueryString("minprice", minPrice, newUrl);
    }
    var maxPrice = $('#max-price-filter').val();
    if (typeof maxPrice != 'undefined' && maxPrice != -1) {
        newUrl = UpdateQueryString("maxprice", maxPrice, newUrl);
    }

    // sorting
    var sort = $('#product-sort').val();
    if (typeof sort != 'undefined') {
        newUrl = UpdateQueryString("listby", sort, newUrl);
    }

    if (newUrl == newUrl.replace("Category", "ListProducts")) {
        //if not a expected async call, just redirect.
        window.location = newUrl;
    } else {
        //redirect to async call and update the list wrapper html on response
        newUrl = newUrl.replace("Category", "ListProducts");

        $.ajax({
            url: newUrl,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                // update URL for re-navigating to the page if required.  
                history.pushState({ id: 'products' }, 'pagetitle', newUrl.replace("ListProducts", "Category"));

                $('#list-products-wrapper').html(data);
                $('#list-products-wrapper').waitForImages(function () {
                    $('.sub-cat-holder-match-height').matchHeight();
                    $('.product-match-height').matchHeight();
                    $('.product-info-match-height').matchHeight();
                });
                LoadFilters();
            },
            error: function () {
                alert('Sorry there was a problem processing your request.');
            }
        });
    }
}
function UpdateQueryString(key, value, url) {
    if (!url) url = window.location.href;
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
        hash;

    if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null)
            return url.replace(re, '$1' + key + "=" + value + '$2$3');
        else {
            hash = url.split('#');
            url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                url += '#' + hash[1];
            return url;
        }
    }
    else {
        if (typeof value !== 'undefined' && value !== null) {
            var separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator + key + '=' + value;
            if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                url += '#' + hash[1];
            return url;
        }
        else
            return url;
    }
}

function ClearFilters(url)
{
    // call url
    var newUrl = UpdateQueryString("pf", null, url);
    newUrl = UpdateQueryString("br", null, newUrl);
newUrl = UpdateQueryString("cf", null, newUrl);
    window.location = newUrl;
}

function InitFilterHeader()
{
    $('.pf-filter-header').click(function () {
        var filter = $(this).closest('.pf-filter-header').next('.pf-filter-items');
        var trigger = $(this).find('.pf-filter-main-dropdown-btn');
        if (filter.is(':visible')) {
            filter.slideUp();
            trigger.addClass('closed');
            trigger.removeClass('open');
        }
        else {
            filter.slideDown();
            trigger.removeClass('closed');
            trigger.addClass('open');
        }
    });
}

function InitCats() {
    $('.filter-cats-sub').hide();
    var selectedLink = $('.cat-selected');

    // open cat parents for selected cat
    var parents = selectedLink.parents('nav');
    parents.show();
    $('.pf-filter-dropdown-btn.opened').parent().siblings('.filter-cats-sub').show();
    selectedLink.parents('.filter-cats').siblings('.pf-filter-item').find('.pf-filter-dropdown-btn').removeClass('closed');
    //parents.find('.filter-dropdown-icon').removeClass('closed');
    //parents.find('.filter-dropdown-icon').addClass('open');

    // open filter specs for selected
    var filterSelectedParent = $('.ecom-filter-val.filter-selected').closest('.pf-filter-dropdown-btn');
    filterSelectedParent.find('.pf-filter-dropdown-btn').removeClass('closed');
    filterSelectedParent.find('.filter-dropdown-list').show();

    $('.pf-filter-dropdown-btn').click(function () {
        var parent = $(this).closest('.pf-filter-item');
        if (parent.next('nav').is(':visible')) {
            parent.next('nav').slideUp();
            parent.find('.pf-filter-dropdown-btn').addClass('closed');
        }
        else {
            parent.next('nav').slideDown();
            parent.find('.pf-filter-dropdown-btn').removeClass('closed');
        }
        //$(this).next('nav').slideToggle().toggleClass('filter-closed');
    });

    if ($('.ecom-filter-val.filter-selected')[0]) {
        ShowClearFilterBtn();
    }
}

function InitSpecs() {

    // open filter specs for selected
    var filterSelectedParent = $('.ecom-filter-val.filter-selected').closest('.filter-dropdown');
    filterSelectedParent.find('.pf-spec-filter-dropdown-btn').removeClass('closed');
    filterSelectedParent.find('.filter-dropdown-list').show();

    $('.pf-spec-filter-dropdown-btn').click(function () {
        var parent = $(this).closest('.pf-filter-item');
        if (parent.next('nav').is(':visible')) {
            parent.next('nav').slideUp();
            parent.find('.pf-spec-filter-dropdown-btn').addClass('closed');
        }
        else {
            parent.next('nav').slideDown();
            parent.find('.pf-spec-filter-dropdown-btn').removeClass('closed');
        }
        //$(this).next('nav').slideToggle().toggleClass('filter-closed');
    });

    if ($('.ecom-filter-val.filter-selected')[0]) {
        ShowClearFilterBtn();
    }
}
function InitColours() {

    // open filter colour if selected
    var filterSelectedParent = $('.ecom-filter-colour-val.filter-selected').closest('.filter-dropdown');
    filterSelectedParent.find('.pf-colour-filter-dropdown-btn').removeClass('closed');
    filterSelectedParent.find('.filter-dropdown-list').show();

    $('.pf-colour-filter-dropdown-btn').click(function () {
        var parent = $(this).closest('.pf-colour-item');
        if (parent.next('nav').is(':visible')) {
            parent.next('nav').slideUp();
            parent.find('.pf-colour-filter-dropdown-btn').addClass('closed');
        }
        else {
            parent.next('nav').slideDown();
            parent.find('.pf-colour-filter-dropdown-btn').removeClass('closed');
        }
        //$(this).next('nav').slideToggle().toggleClass('filter-closed');
    });

    if ($('.ecom-filter-colour-val.filter-selected')[0]) {
        ShowClearFilterBtn();
    }
}

function ShowClearFilterBtn() {
    $('#pf-clear-filters-btn').css('display', 'inline-block');
}